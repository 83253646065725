




















































.selectedItem ::v-deep .v-select__selection--comma {
  margin-bottom: -25px;
}
.role {
  top: 10px;
  position: absolute;
  left: 24px;
  color: var(--v-success-base);
}
